import { Grid, Paper } from "@mui/material";
import { useState } from "react";
import SceneI from "./SceneI";
import SceneII from "./SceneII";
import SceneIII from "./SceneIII";
import SceneIV from "./SceneIV";

export default function CS2101ReflectionPage() {
  const [mainShowing, returnToMain] = useState(true);
  const [SceneIShowing, showSceneI] = useState(false);
  const [SceneIIShowing, showSceneII] = useState(false);
  const [SceneIIIShowing, showSceneIII] = useState(false);
  const [SceneIVShowing, showSceneIV] = useState(false);

  function generateReflectionCard(bgColor, textAlign, cursor) {
    return {
      // bg
      backgroundColor: bgColor,

      // border
      borderRadius: 10,
      borderColor: "black",

      // text
      color: "white",
      fontSize: 30,
      textAlign: textAlign,

      // padding
      padding: 20,

      // cursor
      cursor: cursor,
    };
  }

  function revertToMain() {
    returnToMain(true);
    showSceneI(false);
    showSceneII(false);
    showSceneIII(false);
    showSceneIV(false);
  }

  const sceneIProps = { revertToMain, showSceneI, showSceneII };

  const sceneIIProps = { revertToMain, showSceneII, showSceneI, showSceneIII };

  const sceneIIIProps = {
    revertToMain,
    showSceneIII,
    showSceneII,
    showSceneIV,
  };

  const sceneIVProps = { revertToMain, showSceneIV, showSceneIII, showSceneI };

  return (
    <>
      {mainShowing && (
        <Grid container spacing={5} style={{ padding: 10, marginTop: "20vh" }}>
          <Grid item xs={12}>
            <Paper style={generateReflectionCard("#000000", "left", "")}>
              Filming: "CS2101" (Week 13 Shoot)
              <br />
              Lead: Yash
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              onClick={() => {
                returnToMain(false);
                showSceneI(true);
              }}
              style={generateReflectionCard("#C202F7", "center", "pointer")}
            >
              Phase I
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              onClick={() => {
                returnToMain(false);
                showSceneII(true);
              }}
              style={generateReflectionCard("#8401A9", "center", "pointer")}
            >
              Phase II
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              onClick={() => {
                returnToMain(false);
                showSceneIII(true);
              }}
              style={generateReflectionCard("#5A0272", "center", "pointer")}
            >
              Phase III
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              onClick={() => {
                returnToMain(false);
                showSceneIV(true);
              }}
              style={generateReflectionCard("#32013D", "center", "pointer")}
            >
              Phase IV
            </Paper>
          </Grid>
          {/* {completionStatus === 1 && (
            <Grid item xs={12}>
              <Paper style={generateReflectionCard("#000000", "left")}>
                Conclusion
              </Paper>
            </Grid>
          )} */}
        </Grid>
      )}
      {SceneIShowing && <SceneI {...sceneIProps} />}
      {SceneIIShowing && <SceneII {...sceneIIProps} />}
      {SceneIIIShowing && <SceneIII {...sceneIIIProps} />}
      {SceneIVShowing && <SceneIV {...sceneIVProps} />}
    </>
  );
}
