import { ExitToApp, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button, Box, Paper } from "@mui/material";

export default function SceneIII({
  revertToMain,
  showSceneIII,
  showSceneII,
  showSceneIV,
}) {
  return (
    <div style={{ padding: 20 }}>
      <Paper
        style={{
          backgroundColor: "beige",
          padding: 30,
          color: "black",
          borderRadius: 10,
          marginTop: "20vh",
        }}
      >
        <div style={{ textAlign: "center", fontSize: "30px" }}>
          <u>Phase III: Retake!</u>
        </div>
        <br />
        <p>
          <b>Scene</b>:
          <br />
          "CA3". This scene is the second in the aforementioned two-part
          sequence. There is no verbal presentation but more focus on
          collaborative nonverbal expression from the cast this time.
        </p>
        <p>
          <b>Performance notes</b>:
          <br />
          Yash's determination was at a peak in this phase. He consulted
          outside-peers for reviews of the act that he and his costars put
          together, he took initiative to confirm the director's expectations,
          and he pushed for multiple retakes with his costars. Clearly he wished
          to compensate for "CA2" but more than that, he was understanding and
          embracing "CS2101"'s contribution to his growth in his field.
        </p>
        <p>
          <b>Post-scene notes</b>:
          <br />
          Yash is proud of what he contributed to the act in terms of
          professional delivery and the rigor of content. He would only liked to
          have beautified the work more for his audience; perhaps possible if
          him and his cast began work on this segment much earlier.
        </p>
        <br />
        {/* <Button
        variant="contained"
        style={{
          backgroundColor: "brown",
          color: "white",
          textAlign: "right",
        }}
        onClick={() => {
          showSceneIII(false);
          showSceneII(true);
        }}
        endIcon={<NavigateBefore />}
      >
        Scene II
      </Button> */}
        <Box
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#800000",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              revertToMain();
            }}
            endIcon={<ExitToApp />}
          >
            Set
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: 5,
              backgroundColor: "brown",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              showSceneIII(false);
              showSceneIV(true);
            }}
            endIcon={<NavigateNext />}
          >
            Phase IV
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
