import { ExitToApp, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button, Box, Paper } from "@mui/material";

export default function SceneII({
  revertToMain,
  showSceneII,
  showSceneI,
  showSceneIII,
}) {
  return (
    <div style={{ padding: 20 }}>
      <Paper
        style={{
          backgroundColor: "beige",
          padding: 30,
          color: "black",
          borderRadius: 10,
          marginTop: "20vh",
        }}
      >
        <div style={{ textAlign: "center", fontSize: "30px" }}>
          <u>Phase II: Camera rolling...</u>
        </div>
        <br />
        <p>
          <b>Scene</b>:
          <br />
          "CA2". This scene is one of two in a critical sequence of the movie,
          and the stakes are much higher because Yash and his new co-stars are
          charged with showcasing much more material.
        </p>
        <p>
          <b>Performance notes</b>:
          <br />
          Yash froze during filming. He practiced more than for "CA1" but the
          pressure seemed to have gotten to him. He felt embarrassed at the
          thought of undermining the scene for his costars. Notably, there was
          some attempt at redemption in the tail-end of the scene that required
          dynamic audience engagement, but Yash was grappling with whether this
          was sufficient to embalm his blunder.
        </p>
        <p>
          <b>Post-scene notes</b>:
          <br />
          It's hard to strike a balance between Yash's natural strength -
          improvisation - and not gambling such a crucial scene on improv. He
          needs to work on delivering a rehearsed speech just as comfortably as
          an improvised one. Even if he can leverage strong audience engagement
          to compensate for weaker content, delivering the content as wholly as
          possible still remains elementary.
        </p>
        <br />
        {/* <Box
        style={{
          display: "inline-flex",
          alignItems: "left",
          justifyContent: "left",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "brown",
            color: "white",
            textAlign: "right",
          }}
          onClick={() => {
            showSceneII(false);
            showSceneI(true);
          }}
          endIcon={<NavigateBefore />}
        >
          Scene I
        </Button>
      </Box> */}
        <Box
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#800000",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              revertToMain();
            }}
            endIcon={<ExitToApp />}
          >
            Set
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: 5,
              backgroundColor: "brown",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              showSceneII(false);
              showSceneIII(true);
            }}
            endIcon={<NavigateNext />}
          >
            Phase III
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
