import { NavigateNext, ExitToApp } from "@mui/icons-material";
import { Button, Box, Paper } from "@mui/material";

export default function SceneI({ revertToMain, showSceneI, showSceneII }) {
  return (
    <div style={{ padding: 20 }}>
      <Paper
        style={{
          backgroundColor: "beige",
          padding: 30,
          color: "black",
          borderRadius: 10,
          marginTop: "20vh",
        }}
      >
        <div style={{ textAlign: "center", fontSize: "30px" }}>
          <u>Phase I: Equipment check.</u>
        </div>
        <br />
        <p>
          <b>Scene</b>:
          <br />
          "CA1". 16 new pairs of eyes on Yash in the center.
        </p>
        <p>
          <b>Performance notes</b>:
          <br />
          Yash is tense. He forgot his lines in Rehearsal 1 and it doesn't help
          that his co-stars expect him to be a confident performer. Nonetheless,
          he is aiming above reciting a memorised speech and plans to
          incorporate dynamic body language and keep his audience engaged.
        </p>
        <p>
          <b>Post-scene notes</b>:
          <br />
          Filming "CA1" turned out fairly successful. Yash felt like he met his
          goals and is fitting better into the rigor of CS2101. He aims to
          practice his speech more for the next scene and apply more of the
          techniques taught to inject vibrancy in his presentation.
        </p>
        <br />
        <Box
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#800000",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              revertToMain();
            }}
            endIcon={<ExitToApp />}
          >
            Set
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: 5,
              backgroundColor: "brown",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              showSceneI(false);
              showSceneII(true);
            }}
            endIcon={<NavigateNext />}
          >
            Phase II
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
