import { ExitToApp, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button, Box, Paper } from "@mui/material";

export default function SceneIV({
  revertToMain,
  showSceneIV,
  showSceneIII,
  showSceneI,
}) {
  return (
    <div style={{ padding: 20 }}>
      <Paper
        style={{
          backgroundColor: "beige",
          padding: 30,
          color: "black",
          borderRadius: 10,
          marginTop: "20vh",
        }}
      >
        <div style={{ textAlign: "center", fontSize: "30px" }}>
          <u>Phase IV: Postproduction</u>
        </div>
        <br />
        <p>
          <b>Concluding notes</b>:
          <br />
          "CA1", "CA2" and "CA3" together have taught Yash that{" "}
          <i>communicating</i> transcends merely <i>presenting</i>. He has
          nurtured an appreciation for nonverbal techniques, cast synergy,
          visual cues, and consistency of expression. Specifically, by putting
          these elements into frequent practice and with the help of timely and
          dynamic feedback from the director, he learned a lot about his
          strengths & weaknesses and the theory underlying effective
          communication.
        </p>
        <br />
        {/* <Button
        variant="contained"
        style={{
          backgroundColor: "brown",
          color: "white",
          textAlign: "right",
        }}
        onClick={() => {
          showSceneIV(false);
          showSceneIII(true);
        }}
        endIcon={<NavigateBefore />}
      >
        Scene III
      </Button> */}
        <Box
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#800000",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              revertToMain();
            }}
            endIcon={<ExitToApp />}
          >
            Set
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: 5,
              backgroundColor: "brown",
              color: "white",
              textAlign: "right",
            }}
            onClick={() => {
              showSceneIV(false);
              showSceneI(true);
            }}
            endIcon={<NavigateNext />}
          >
            Phase I
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
